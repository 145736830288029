import React from 'react'
import ReactDOM from 'react-dom';
import * as UI from "antd";
import { PageHeader } from '@ant-design/pro-layout';

var Loading=()=>{
    const loadDom = document.createElement("div")
    loadDom.setAttribute("id", "loading")
    loadDom.ariaHidden = "true"
    loadDom.style.zIndex = "202410"
    loadDom.style.width = "100vw"
    loadDom.style.height = "100vh"
    loadDom.style.position = "fixed"
    loadDom.style.top = 0
    loadDom.style.left = 0;
    loadDom.style.backgroundColor = "rgba(0, 0, 0, 0.6)"
    loadDom.style.textAlign = "center"
    loadDom.style.paddingTop = "20vh"
    const loadChild = document.createElement("div");
    loadDom.innerHTML = '<div aria-live="polite" aria-busy="true">' +
      '<span style="font-size: 36px; animation: rotate 2s linear infinite; color: #00b96b; margin-bottom: 10px;" class="fa fa-spinner">' +
      '</span>' +
      '<div class="ant-spin-text" style="color: #00b96b;">Loading...</div>' +
      '</div>'
    // loadDom.appendChild(spinSpan);
    loadDom.appendChild(loadChild);
    document.body.appendChild(loadDom);
}

var HasLoaded=()=>{
    try {
        document.body.removeChild(document.getElementById("loading"));
    } catch (e) {
        console.log(e);
    }

}

var LoadingStart = Loading

var LoadingEnded = HasLoaded

export {
    HasLoaded,
    Loading,
    LoadingStart,
    LoadingEnded
}
